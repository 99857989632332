import {createSlice} from "@reduxjs/toolkit";

const initialProductVariantsState = {
  listLoading: false,
  actionsLoading: false,
  total_count: 0,
  entities: null,
  productVariantForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const productVariantsSlice = createSlice({
  name: "productVariants",
  initialState: initialProductVariantsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductVariantById
    productVariantFetched: (state, action) => {
      state.actionsLoading = false;
      state.productVariantForEdit = action.payload.productVariantForEdit;
      state.error = null;
    },
    // findProductVariants
    productVariantsFetched: (state, action) => {
      const { total_count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.total_count = total_count;
    },
    // createProductVariant
    productVariantCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.productVariant);
    },
    // updateProductVariant
    productVariantUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.productVariant.id) {
          entity = {...entity, ...action.payload.productVariant};
        }
        return entity;
      });
    },
    // deleteProductVariant
    productVariantDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteProductVariants
    productVariantsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
