import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function Input({
                          field, // { name, value, onChange, onBlur, tooltip }
                          form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          label,
                          withFeedbackLabel = false,
                          customFeedbackLabel,
                          type = "text",
                          ...props
                      }) {

    function flatten(data, c) {
        var result = {}
        for (var i in data) {
            if (typeof data[i] == 'object') Object.assign(result, flatten(data[i], c + '.' + i))
            else result[(c + '.' + i).replace(/^\./, "")] = data[i]
        }
        return result
    }
    let flattenedErrors = flatten(errors, '')
    let flattenedTouch = flatten(errors, '')
    return (
        <>
            {label && <label>{label}</label>}{props.tooltip && <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    {props.tooltip}
                </Tooltip>
            }
        >
            <i style={{marginLeft: 10}} className="fa fa-question-circle fa-sm"/>
        </OverlayTrigger>}
            <input
                disabled={!!props?.disabled}
                type={type}
                className={getFieldCSSClasses(flattenedTouch[field.name], flattenedErrors[field.name])}
                {...field}
                {...props}
                value={field.value == null ? '' : field.value}
            />
            {flattenedErrors[field.name] && <div className="invalid-feedback">
                {flattenedErrors[field.name]}
            </div>}
            {props && props.msg && <div style={{display: 'block', width: '100%'}}>
                {props.msg}
            </div>}
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={label}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}
