/* Pagination Helprs */
import React from "react";

export const getPagesCount = (totalSize, sizePerPage) => {
    return Math.ceil(totalSize / sizePerPage);
};

export const getPages = (page, pagesCount, paginationSize) => {
    const result = [];
    if (!page) {
        return result;
    }

    if (pagesCount === 1) {
        result.push(1);
        return result;
    }

    if (pagesCount < page) {
        return result;
    }

    if (pagesCount < paginationSize + 1) {
        for (let i = 1; i < pagesCount + 1; i++) {
            result.push(i);
        }
        return result;
    }

    if (page === 1) {
        for (let i = 1; i < paginationSize + 1; i++) {
            if (i < pagesCount) {
                result.push(i);
            }
        }
        return result;
    }

    if (page === pagesCount) {
        for (let i = pagesCount - paginationSize + 1; i <= pagesCount; i++) {
            if (i <= pagesCount) {
                result.push(i);
            }
        }
        return result;
    }

    const shiftCount = Math.floor(paginationSize / 2);
    if (shiftCount < 1) {
        result.push(page);
        return result;
    }

    //
    if (page < shiftCount + 2) {
        for (let i = 1; i < paginationSize + 1; i++) {
            result.push(i);
        }
        return result;
    }

    if (pagesCount - page < shiftCount + 2) {
        for (let i = pagesCount - paginationSize; i < pagesCount + 1; i++) {
            result.push(i);
        }
        return result;
    }

    for (let i = page - shiftCount; i < page; i++) {
        if (i > 0) {
            result.push(i);
        }
    }
    result.push(page);
    for (let i = page + 1; i < page + shiftCount + 1; i++) {
        if (i <= pagesCount) {
            result.push(i);
        }
    }

    return result;
};
export const prepareFilter = (filters) => {
    const parsedFilter = {};
    ['reprice_status', 'id_sku', 'id_from_channel', 'status', 'delete_state', 'type', 'id_walmart', 'id_bestbuy', 'id_amazon', 'id', 'amazon_in_stock', 'walmart_in_stock', 'bestbuy_in_stock', 'is_curated', 'ingestion_id', 'listing_ids', 'listing_job.virtual_product_id', 'listing_job.virtual_in_stock', 'listing_job.virtual_product_strategy', 'channel_status', 'manufacturer', 'model', 'condition', 'is_buy_box_winner', 'is_at_min', 'is_at_max', 'is_fba', 'is_lowest_seller', 'is_only_seller', 'min_more_than_buybox', 'margin_amount_more_than_buybox_diff', 'walmart_notify_in_stock', 'walmart_notify_price_is_down'].forEach((fld) => {
        if (fld in filters) {
            parsedFilter[fld] = filters[fld].filterVal
        }
    });
    ['price_standard', 'price_min', 'price_max', 'price_buybox', 'price_buybox_diff', 'price_shipping_cost', 'qty','seller_cnt', 'margin_amount', 'margin_percent', 'buybox_rank', 'price_cost', 'channel_fee_total', 'amazon_price', 'amazon_walmart_margin', 'amazon_bestbuy_margin', 'walmart_price', 'bestbuy_price', 'amazon_sales_rank', 'walmart_review_avg', 'walmart_review_cnt', 'amazon_offer_cnt', 'created_at', 'amazon_sales_rank_main', 'walmart_notify_price_limit'].forEach((fld) => {
        if (fld in filters) {
            if (filters[fld].filterVal.number !== '') {
                if (filters[fld].filterVal.comparator === '<') {
                    parsedFilter[fld + '_lt'] = parseFloat(filters[fld].filterVal.number)

                }
                if (filters[fld].filterVal.comparator === '>') {
                    parsedFilter[fld + '_gt'] = parseFloat(filters[fld].filterVal.number)
                }
                if (filters[fld].filterVal.comparator === '=') {
                    parsedFilter[fld] = parseFloat(filters[fld].filterVal.number)
                }
            }
        }

    });
    ['created_at'].forEach((fld) => {
        if (fld in filters) {
            if (filters[fld].filterVal.date !== '') {
                if (filters[fld].filterVal.comparator === '<') {
                    parsedFilter[fld + '_lt'] = filters[fld].filterVal.date

                }
                if (filters[fld].filterVal.comparator === '>') {
                    parsedFilter[fld + '_gt'] = filters[fld].filterVal.date
                }
            }
        }

    });
    ['title', 'email', 'first_name', 'last_name', 'name', 'listing'].forEach((fld) => {
        if (fld in filters) {
            parsedFilter['q'] = filters[fld].filterVal
        }
    });

    if ('reprice_rules' in filters) {
        parsedFilter['reprice_rule_id'] = filters['reprice_rules'].filterVal
    }
    if ('system_tags' in filters) {
        parsedFilter[filters['system_tags'].filterVal] = true
    }

    return parsedFilter;
};

export function getHandlerTableChange(setQueryParams) {
    return (type, {page, sizePerPage, sortField, sortOrder, filters, data, cellEdit}) => {
        const pageNumber = page || 1;
        setQueryParams((prev) =>
            type === "sort"
                ? {...prev, sortOrder, sortField}
                : type === "pagination"
                ? {...prev, pageNumber, pageSize: sizePerPage}
                : type === "filter"
                    ? {...prev, pageNumber, filter: prepareFilter(filters)}
                    : prev
        );
    };
}

export function PleaseWaitMessage({entities}) {
    return <>{entities === null && <div>Please wait...</div>}</>;
}

export function NoRecordsFoundMessage({entities}) {
    const customersList = entities === null ? [] : entities;
    return (
        <>
            {customersList.length === 0 && entities !== null && (
                <div>No records found</div>
            )}
        </>
    );
}
