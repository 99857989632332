/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, {useRef, useState} from "react";
import {Provider} from "react-redux";
import {BrowserRouter, Redirect} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {Routes} from "../app/Routes";
import {I18nProvider} from "../_metronic/i18n";
import {LayoutSplashScreen, MaterialThemeProvider} from "../_metronic/layout";
import axios from "axios";
import {useSnackbar} from "notistack";
import Button from "@material-ui/core/Button";
import {Mixpanel} from "./common/Mixpanel";

export default function App({store, persistor, basename}) {

    return (
        /* Provide Redux store */
        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen/>}>
                {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                <React.Suspense fallback={<LayoutSplashScreen/>}>
                    {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                    <BrowserRouter basename={basename}>
                        {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                        <MaterialThemeProvider>
                            {/* Provide `react-intl` context synchronized with Redux state.  */}
                            <I18nProvider>
                                {/* Render routes with provided `Layout`. */}
                                <Routes/>
                            </I18nProvider>
                        </MaterialThemeProvider>
                    </BrowserRouter>
                </React.Suspense>
            </PersistGate>
        </Provider>
    );
}
