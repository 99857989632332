import {createSlice} from "@reduxjs/toolkit";

const initialChannelsState = {
  listLoading: false,
  actionsLoading: false,
  total_count: 0,
  entities: null,
  channelForEdit: undefined,
  error: null,
  authUrl: null
};
export const callTypes = {
  list: "list",
  action: "action"
};
export const channelsSlice = createSlice({
  name: "channels",
  initialState: initialChannelsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getChannelById
    channelFetched: (state, action) => {
      state.actionsLoading = false;
      state.channelForEdit = action.payload.channelForEdit;
      state.error = null;
    },
    // findChannels
    channelsFetched: (state, action) => {
      const { total_count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.total_count = total_count;
    },
    // createChannel
    channelCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.channel);
    },
    authUrlFetchCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.authUrl = action.payload.url;
    },
    // updateChannel
    channelUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      if (state.entities != null) {
        state.entities = state.entities.map(entity => {
          if (entity.id === action.payload.channel.id) {
            entity = {...entity, ...action.payload.channel};
          }
          return entity;
        });
      }
    },
    // deleteChannel
    channelDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteChannels
    channelsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // channelsUpdateState
    channelsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
