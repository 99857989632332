import React, {useMemo, useState} from "react";
import objectPath from "object-path";
import {Link, Redirect, useHistory} from "react-router-dom";
import {checkInUrl, toAbsoluteUrl} from "../../../../_helpers";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {HeaderMenu} from "./HeaderMenu";
import SVG from "react-inlinesvg";
import {shallowEqual, useSelector} from "react-redux";

export function HeaderMenuWrapper() {
    const uiService = useHtmlClassService();
    const history = useHistory();
    const [searchValue, setSearchValue] = useState('');
    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            ktMenuClasses: uiService.getClasses("header_menu", true),
            rootArrowEnabled: objectPath.get(
                uiService.config,
                "header.menu.self.root-arrow"
            ),
            menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
            headerMenuAttributes: uiService.getAttributes("header_menu"),
            headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
            ulClasses: uiService.getClasses("header_menu_nav", true),
            disabledAsideSelfDisplay:
                objectPath.get(uiService.config, "aside.self.display") === false
        };
    }, [uiService]);
    const getHeaderLogo = () => {
        let result = "logo-light.png";
        if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
            result = "logo-dark.png";
        }
        return toAbsoluteUrl(`/media/logos/${result}`);
    };
    const {is_pro, mychannels} = useSelector(
        (state) => ({
            mychannels: state?.auth?.user?.account?.channels ? state?.auth?.user?.account?.channels : null,
        }),
        shallowEqual
    );
    const handleSearch = (e) => {
        e.preventDefault();
        if (searchValue !== '') {
            let val = searchValue
            setSearchValue('')
            let currentPath = window.location.pathname
            history.push(`/app/listings/${mychannels[0].channel_type}/${mychannels[0].id}?qParams=${btoa(JSON.stringify({filter: {q: val, channel_type: "amazon", channel_id: mychannels[0].id}}))}`);
        }
    }
    return <>
        {/*begin::Header Menu Wrapper*/}
        <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
            {layoutProps.disabledAsideSelfDisplay && (
                <>
                    {/*begin::Header Logo*/}
                    <div className="header-logo">
                        <Link to="/">
                            <img alt="logo" src={getHeaderLogo()}/>
                        </Link>
                    </div>
                    {/*end::Header Logo*/}
                </>
            )}
            {/*begin::Header Menu*/}
            {/*<HeaderMenu layoutProps={layoutProps} />*/}
            {/*end::Header Menu*/}
            {mychannels && <div className="mt-3 mr-10" style={{minWidth: 600}}>
                <div>
                    <form className="quick-search-form" onSubmit={(e) => handleSearch(e)}>
                        <div className="input-group">

                            <input
                                type="text"
                                autoFocus={true}
                                placeholder="Search by product, sku, ASIN or custom tag"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                className="form-control"
                            />
                            <div className={`input-group-append`}>
                    <span className="input-group-text">
                      <a onClick={(e) => handleSearch(e)}>
                      <span className="svg-icon svg-icon-md">
                        <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/icons/General/Search.svg"
                            )}
                        />
                      </span>
                          </a>
                    </span>
                            </div>

                        </div>
                    </form>
                </div>
            </div>}
        </div>
        {/*Header Menu Wrapper*/}
    </>
}
