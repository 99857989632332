import React, {useMemo} from "react";
import objectPath from "object-path";
// LayoutContext
import {useHtmlClassService} from "../_core/MetronicLayout";
// Import Layout components
import {Header} from "./header/Header";
import {HeaderMobile} from "./header-mobile/HeaderMobile";
import {Aside} from "./aside/Aside";
import {Footer} from "./footer/Footer";
import {LayoutInit} from "./LayoutInit";
import {SubHeader} from "./subheader/SubHeader";
import {ScrollTop} from "./extras/ScrollTop";
import {Alert} from "react-bootstrap";
import {shallowEqual, useSelector} from "react-redux";
import {DemoOrIntegrateModal} from "../../../app/pages/DemoOrIntegrateModal";
import {useHistory} from "react-router-dom";

export function Layout({children}) {
    const uiService = useHtmlClassService();
    const history = useHistory();
    // Layout settings (cssClasses/cssAttributes)
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, "self.layout"),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended")
        };
    }, [uiService]);
    const {isDemo, show_demo_or_integrate} = useSelector(
        (state) => ({
            isDemo: state.auth.user.account.is_demo,
        }),
        shallowEqual
    );
    const clickAlertLink = () => {
        history.push("/app/channels/import-from-channels")
    }
    return layoutProps.selfLayout !== "blank" ? (
        <>
            {/*begin::Main*/}
            <HeaderMobile/>
            {(isDemo !== null && isDemo === true) && <DemoOrIntegrateModal/>}
            <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {layoutProps.asideDisplay && (<Aside/>)}
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        {(isDemo !== null && isDemo === true) && <Alert variant="warning" style={{margin: 10, fontSize: '16px'}} >
                            You are viewing a SaleDrum demo. When you're ready, <Alert.Link href="#" onClick={() => clickAlertLink()}>integrate with your Amazon channel</Alert.Link> to start maximizing your profit. It's <b>FREE and no credit card required</b>. Data shown below is for demonstration purposes
                        </Alert>}

                        <Header/>
                        {/*begin::Content*/}
                        <div
                            id="kt_content"
                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                        >
                            {layoutProps.subheaderDisplay && <SubHeader/>}
                            {/*begin::Entry*/}
                            {!layoutProps.contentExtended && (
                                <div className="d-flex flex-column-fluid">
                                    {/*begin::Container*/}
                                    <div className={layoutProps.contentContainerClasses}>
                                        {children}
                                    </div>
                                    {/*end::Container*/}
                                </div>
                            )}

                            {layoutProps.contentExtended && {children}}
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        <Footer/>
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            <ScrollTop/>
            {/*end::Main*/}
            <LayoutInit/>
        </>
    ) : (
        // BLANK LAYOUT
        <div className="d-flex flex-column flex-root">{children}</div>
    );
}
