/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import store, {persistor} from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";


import {
    MetronicLayoutProvider,
    MetronicSplashScreenProvider,
    MetronicSubheaderProvider
} from "./_metronic/layout";
import {MetronicI18nProvider} from "./_metronic/i18n";
import {SnackbarProvider} from "notistack";
import dayjs from "dayjs";
import {SupportFormProvider} from "./app/pages/SupportFormProvider";
const {PUBLIC_URL, REACT_APP_API_URL} = process.env;
axios.defaults.baseURL = REACT_APP_API_URL;


var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

ReactDOM.render(
    <MetronicI18nProvider>
        <MetronicLayoutProvider>
            <MetronicSubheaderProvider>
                <MetronicSplashScreenProvider>
                    <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: "center", vertical: "top"}} variant={"success"}>
                        <SupportFormProvider>
                            <App store={store} persistor={persistor} basename={PUBLIC_URL}/>
                        </SupportFormProvider>
                    </SnackbarProvider>
                </MetronicSplashScreenProvider>
            </MetronicSubheaderProvider>
        </MetronicLayoutProvider>
    </MetronicI18nProvider>,
    document.getElementById("root")
);
