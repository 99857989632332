/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from "react";
import ApexCharts from "apexcharts";
import {KTUtil} from "../../_metronic/_assets/js/components/util";

export function TotalSalesWidget({className, symbolShape, baseColor, data, totalSales, title, region, height}) {

    useEffect(() => {
        const element = document.getElementById("kt_stats_widget_10_chart");

        if (!element || !data) {
            return;
        }

        const height = parseInt(KTUtil.css(element, "height"));
        const options = getChartOption(height + 100, data, region);
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [data]);

    return (<>{region && data && totalSales && title && baseColor && className ?
        <div className={`card card-custom ${className}`} >
            <div className="card-header border-0 align-items-center">
                <h3 className="card-title font-weight-bolder text-dark">
                    {title}
                </h3>
                <span className="text-dark-75 font-weight-bolder font-size-h2  ml-10">
                                  {totalSales.toLocaleString(region.locale, {style: 'currency', currency: region.currency})}
                              </span>
            </div>
            {/* begin::Body */}
            <div className="card-body p-0">
                <div
                    id="kt_stats_widget_10_chart"
                    className="card-rounded-bottom"
                    data-color={baseColor}
                    style={{height: height ? height : 265}}
                />
            </div>
        </div>: ''}
        </>
    );
}

function getChartOption(height, data, region) {
    return {
        series: [{
            name: 'Total Sales',
            data: data
        }],
        chart: {
            type: 'area',
            stacked: false,
            height: height,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        title: {
            text: '',
            align: 'left'
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val.toLocaleString(region.locale, {style: 'currency', currency: region.currency});
                },
            },
            title: {
                text: 'Total Daily Sales'
            },
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val.toLocaleString(region.locale, {style: 'currency', currency: region.currency});
                }
            }
        }

    };
}
