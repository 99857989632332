/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function MostCompetitiveWidget({className, bestSellers, goRoute}) {
    return (
        <>
            {/* begin::List Widget 14 */}
            <div className={`card card-custom ${className}  ribbon ribbon-clip ribbon-left`} style={{minHeight: 450, height: "unset"}}>
                <div className="ribbon-target" style={{top: 15, height: 45}}>
                    <span className="ribbon-inner bg-warning"></span><i className="fa flaticon-alarm-1" style={{color: "white"}}></i>
                </div>
                {/* begin::Header */}
                <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-dark ml-5">
                        Competitive Products
                    </h3>
                </div>
                {/* end::Header */}

                {/* begin::Body */}
                <div className="card-body pt-2">
                    {bestSellers && Object.keys(bestSellers).length > 0 && bestSellers.map((row, index) => (
                        <div className="row mt-5" key={`best-sellers${index}`}>
                            <div className="col-12">
                                <div>{row && row.title && <a
                                    onClick={() => goRoute(`/app/listings/${row.channel_type}/${row.channel_id}/${row.id}/edit`)}
                                    className="text-dark-75 font-weight-bold text-hover-primary font-size-md"
                                >{row.title.substr(0, 80)}{row.title.length > 80 && '..'}</a>}</div>
                                 <div className="text-muted font-weight-bold font-size-sm my-1">
                                    {row.activity_cnt} reprice events last week
                                </div>
                            </div>


                        </div>
                    ))}
                </div>
                {/* end::Body */}
            </div>
            {/* end::List Widget 14 */}
        </>
    );
}
