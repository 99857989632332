import React, {useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {login} from "../_redux/authCrud";
import {Mixpanel} from "../../../common/Mixpanel";
import Recaptcha from 'react-google-invisible-recaptcha';

const initialValues = {
    email: "",
    password: "",
    captcha: ""
};

function Login(props) {
    const {intl} = props;
    const [loading, setLoading] = useState(false);
    const refRecaptcha = useRef(null);
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Wrong email format")
            .min(5, "Minimum 5 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        password: Yup.string()
            .min(6, "Your password needs to be at least 6 characters length")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        captcha: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            setTimeout(() => {
                login(values.email, values.password, values.captcha)
                    .then(({data: {refresh_token, access_token, user_id}}) => {
                        disableLoading();
                        window.localStorage.setItem("access_token", access_token);
                        window.localStorage.setItem("refresh_token", refresh_token);
                        Mixpanel.identify(user_id);
                        Mixpanel.people.set({
                            $email: values.email,
                        });
                        Mixpanel.track('Successful login');
                        props.login(access_token);
                    })
                    .catch((err) => {
                        refRecaptcha.current.callbacks.reset();
                        refRecaptcha.current.callbacks.execute();
                        values.captcha = refRecaptcha.current.callbacks.getResponse();
                        let msg = 'Can not log in at this time, please try again later';
                        if (Array.isArray(err?.response?.data?.error_message)) {
                            msg = err.response.data.error_message[0]
                        } else if (err?.response?.data?.error_message) {
                            msg = err.response.data.error_message
                        } else if (err?.response?.data?.message) {

                            if (err.response.data.message === 'Wrong token') {
                                msg = 'Your session is expired. Plaease log back in'
                            } else {
                                msg = err.response.data.message
                            }
                        }
                        disableLoading();
                        setSubmitting(false);
                        setStatus(
                            msg
                        );
                        Mixpanel.track('Failed login', {
                            'email': values.email,
                            'error': msg
                        });
                    });
            }, 1000);
        },
    });
    const handleBlur2 = (e) => {
        if (!formik.values.captcha) {
            refRecaptcha.current.callbacks.execute();
            formik.setSubmitting(true);
        }
        formik.handleBlur(e);
    }
    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.TITLE"/>
                </h3>
                <p className="text-muted font-weight-bold">
                    Enter your username and password
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Email"
                        type="email"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "email"
                        )}`}
                        name="email"
                        onChange={formik.handleChange}
                        {...formik.getFieldProps("email")}
                        onBlur={handleBlur2}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "password"
                        )}`}
                        name="password"
                        onChange={formik.handleChange}
                        {...formik.getFieldProps("password")}
                        onBlur={handleBlur2}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <Link
                        to="/auth/forgot-password"
                        className="text-dark-50 text-hover-primary my-3 mr-2"
                        id="kt_login_forgot"
                    >
                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON"/>
                    </Link>
                    <Recaptcha ref={refRecaptcha} sitekey={process.env.REACT_APP_CAPTCHA_TOKEN} onExpired={() => {
                        refRecaptcha.current.callbacks.reset();
                        refRecaptcha.current.callbacks.execute();
                        formik.setFieldValue("captcha", refRecaptcha.current.callbacks.getResponse());
                    }} onResolved={() => {
                        formik.setFieldValue("captcha", refRecaptcha.current.callbacks.getResponse());
                        formik.setSubmitting(false);
                    }}/>
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        // disabled={formik.isSubmitting}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Sign In</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
                <div className="text-center">
                    <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
                    <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
