import React from "react";
import {Modal} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import axios from "axios";

const {REACT_APP_STRIPE_KEY_MONTH, REACT_APP_STRIPE_KEY_YEAR} = process.env;

export function PaymentDialog({show, onHide}) {

    const getPaymentUrl = (type) => {
        let keys = {
            month: REACT_APP_STRIPE_KEY_MONTH,
            year: REACT_APP_STRIPE_KEY_YEAR
        }
        axios.post(`/stripe-redirect`, {lookup_key:keys[type]}).then((response) => {
            const{url} = response.data;
             if (url !== null && url !== '') {
                 window.location = url
             }
        })
    }
    return (
        <Modal
            show={show}
            dialogClassName="modal-40w"
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Upgrade your account
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
                <div id="kt_quick_actions">
                    <div className="offcanvas-content pr-3 mr-n3">
                        <div className="row gutter-b">
                            <div className="col-6">
                                <a
                                    onClick={() => getPaymentUrl('year')}
                                    className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
                                >
                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                  <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale1.svg")}
                  />
                </span>
                                    <span className="d-block font-weight-bold font-size-h6 mt-2">
                  $79 @ month
                </span>
                                    <span className="d-block font-size-lg">
                      * paid annually
                    </span>
                                </a>
                            </div>
                            <div className="col-6">
                                <a
                                    onClick={() => getPaymentUrl('month')}
                                    className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
                                >
                <span className="svg-icon svg-icon-3x svg-icon-primary m-0">
                  <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Sale2.svg")}
                  />
                </span>
                                    <span className="d-block font-weight-bold font-size-h6 mt-2">
                  $99 @ month
                </span>
                                    <span className="d-block font-size-lg">
                     * paid monthly
                    </span>
                                </a>
                            </div>
                        </div>
                         <div className="row gutter-b">
                             <ul>
                                 <li><b>Unlimited</b> Sales Channels</li>
                                 <li><b>Unlimited</b> Listings</li>
                                 <li><b>3 to 15 min</b> update time</li>
                                 <li>20,000 repricing event is included</li>
                                 <li>$1.99 for each add'l 1,000 reprice event</li>
                                 <li>Cancel anytime within <b>30 days</b></li>
                             </ul>
                         </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}