import React from "react";
import {Modal} from "react-bootstrap";
import Iframe from "react-iframe";

export function SupportForm({show, onHide, requestParams}) {
    return (
        <Modal
            show={show}
            size="lg"
            onHide={onHide}
            centered
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Request Support
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
                <Iframe url={`https://saledrum.com/support-request/?${requestParams}`}
                        width="100%"
                        height="400px"
                        id=""
                        frameBorder="0"
                        className=""
                        display="block"
                        position="relative"/>
            </Modal.Body>

        </Modal>
    );
}