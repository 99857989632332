import React, {useRef, useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {forgotPasswordVerify, login} from "../_redux/authCrud";
import Recaptcha from "react-google-invisible-recaptcha";


function ForgotPasswordVerify(props) {
    const {intl} = props;
    const [loading, setLoading] = useState(false);
    let match = useRouteMatch('/auth/forgot-password-verify/:password_token')
    const initialValues = {
        confirmPassword: "",
        password: "",
        password_token: match.params.password_token,
        captcha: ""
    };
    const refRecaptcha = useRef(null);
    const LoginSchema = Yup.object().shape({
        confirmPassword: Yup.string()
            .min(6, "Your password needs to be at least 6 characters length")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ).when("password", {
                is: (val) => (val && val.length > 0),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Password and Confirm Password didn't match"
                ),
            }),
        password: Yup.string()
            .min(6, "Your password needs to be at least 6 characters length")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            setTimeout((err) => {
                forgotPasswordVerify(values.password_token, values.password, values.captcha)
                    .then(({data: {refresh_token, access_token}}) => {
                        disableLoading();
                        window.localStorage.setItem("access_token", access_token);
                        window.localStorage.setItem("refresh_token", refresh_token);
                        props.login(access_token);
                    })
                    .catch((err) => {
                        refRecaptcha.current.callbacks.reset();
                        refRecaptcha.current.callbacks.execute();
                        values.captcha = refRecaptcha.current.callbacks.getResponse();
                        disableLoading();
                        setSubmitting(false);
                        if (err?.response?.data?.error_message) {
                            setStatus(
                                Array.isArray(err.response.data.error_message) ? err.response.data.error_message[0] : err.response.data.error_message
                            );
                        } else {
                            setStatus('Password update failed')
                        }
                    });
            }, 1000);
        },
    });
    const handleBlur2 = (e) => {
        if (refRecaptcha?.current?.callbacks && !formik.values.captcha) {
            refRecaptcha.current.callbacks.execute();
            formik.setSubmitting(true);
        }
        formik.handleBlur(e);
    }
    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    Change Password
                </h3>
                <p className="text-muted font-weight-bold">
                    Enter your new pasword
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "password"
                        )}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                        onBlur={handleBlur2}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                {/* begin: Confirm Password */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Confirm Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "confirmPassword"
                        )}`}
                        name="confirmPassword"
                        {...formik.getFieldProps("confirmPassword")}
                        onBlur={handleBlur2}
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.confirmPassword}
                            </div>
                        </div>
                    ) : null}
                </div>
                {/* end: Confirm Password */}
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <Recaptcha ref={refRecaptcha} sitekey={process.env.REACT_APP_CAPTCHA_TOKEN} onExpired={() => {
                        refRecaptcha.current.callbacks.reset();
                        refRecaptcha.current.callbacks.execute();
                        formik.setFieldValue("captcha", refRecaptcha.current.callbacks.getResponse());
                    }} onResolved={() => {
                                formik.setFieldValue("captcha", refRecaptcha.current.callbacks.getResponse());
                                formik.setSubmitting(false);
                            }}/>
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Update Password</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(ForgotPasswordVerify));
