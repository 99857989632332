import React, {useMemo, useState} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {SearchDropdown} from "../extras/dropdowns/search/SearchDropdown";
import {QuickUserToggler} from "../extras/QuiclUserToggler";
import {PaymentDialog} from "../../../../app/pages/PaymentDialog";
import Chip from "@material-ui/core/Chip";
import {shallowEqual, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export function Topbar() {
    const [showPayment, setShowPayment] = useState(false);
    const history = useHistory();
    const uiService = useHtmlClassService();
    const {is_pro, is_demo} = useSelector(
        (state) => ({
            is_pro: state?.auth?.user?.account?.is_pro ? state.auth.user.account.is_pro : false,
            is_demo: state?.auth?.user?.account?.is_demo ? state.auth.user.account.is_demo : false,
        }),
        shallowEqual
    );
    const layoutProps = useMemo(() => {
        return {
            viewSearchDisplay: objectPath.get(
                uiService.config,
                "extras.search.display"
            ),
            viewNotificationsDisplay: objectPath.get(
                uiService.config,
                "extras.notifications.display"
            ),
            viewQuickActionsDisplay: objectPath.get(
                uiService.config,
                "extras.quick-actions.display"
            ),
            viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
            viewQuickPanelDisplay: objectPath.get(
                uiService.config,
                "extras.quick-panel.display"
            ),
            viewLanguagesDisplay: objectPath.get(
                uiService.config,
                "extras.languages.display"
            ),
            viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
        };
    }, [uiService]);
    const goPro = (url) => {
        history.push(url)
    }
    return (
        <div className="topbar">

            <div className="topbar-item">
                <PaymentDialog show={showPayment} onHide={() => setShowPayment(false)}/>
                {!is_pro && !is_demo && <a onClick={() => goPro('/app/upgrade')} className="btn btn-warning"><i className="fa fa-rocket"/> Upgrade Plan</a>}
                {is_pro && <Chip
                    label="PRO ACCOUNT"
                    variant="outlined"
                    color="primary"
                />}
            </div>

            {layoutProps.viewSearchDisplay && <SearchDropdown/>}

            {layoutProps.viewUserDisplay && <QuickUserToggler/>}
        </div>
    );
}