/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useState} from "react";
import {Redirect, Switch, Route, useHistory} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import {Logout, AuthPage, actions} from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {useSnackbar} from "notistack";
import axios from "axios";
import Button from "@material-ui/core/Button";
import {Mixpanel} from "./common/Mixpanel";

export function Routes() {
    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    function getLocalAccessToken() {
        const accessToken = window.localStorage.getItem("access_token");
        return accessToken;
    }

    function getLocalRefreshToken() {
        const refreshToken = window.localStorage.getItem("refresh_token");
        return refreshToken;
    }

    function refreshToken() {
        return axios.create().post("/refresh-token", null, {headers: {Authorization: `Bearer ${getLocalRefreshToken()}`}});
    }

    const action = key => (
        <>
            <Button onClick={() => {
                closeSnackbar(key)
            }}>
                Dismiss
            </Button>
        </>
    );

    const traverse = (obj, msgs) => {
        for (let k in obj) {
            if (obj[k] && typeof obj[k] === 'object') {
                traverse(obj[k], msgs)
            } else {
                msgs.push(obj[k])
            }
        }
        return msgs
    }
    axios.interceptors.request.use(
        (config) => {
            const token = getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        (res) => {
            if ((res.status === 200 || res.status === 201) && res.data &&  ('message' in res.data)) {
                enqueueSnackbar(res.data.message, {
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                    action
                })
            }
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const rs = await refreshToken();
                        const {access_token} = rs.data;
                        window.localStorage.setItem("access_token", access_token);
                        axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

                        return axios(originalConfig);
                    } catch (_error) {
                        if (_error.response && _error.response.data) {
                            return Promise.reject(_error.response.data);
                        }

                        return Promise.reject(_error);
                    }
                }

                if (err.response.status === 403 && err.response.data) {
                    if ('error_message' in err.response.data && (err.response.data['error_message'] === 'Account is expired')) {
                        history.push('/app/upgrade?expired=1');
                    }else{
                        return Promise.reject(err.response.data);
                    }
                }

                if (err.response.status === 400 || err.response.status === 422) {
                    let msg = 'Error'
                    if (Array.isArray(err?.response?.data?.error_message)) {
                        msg = err.response.data.error_message[0]
                    } else if (err?.response?.data?.error_message) {
                        msg = err.response.data.error_message
                    } else if (err?.response?.data?.message) {

                        if (err.response.data.message === 'Wrong token') {
                            msg = 'Your session is expired. Plaease log back in'
                            dispatch(actions.logout());
                        } else {
                            msg = err.response.data.message
                        }
                    }
                    if (typeof msg === 'object') {
                        let msgs = traverse(msg, [])
                        if (msgs.length > 0) {
                            msg = [...new Set(msgs)].join()
                        }
                    }
                    enqueueSnackbar(msg, {
                        preventDuplicate: true,
                        variant: 'error',
                        autoHideDuration: 3000,
                        action
                    })
                    Mixpanel.track('Failed api request', {
                        'error': msg
                    })
                    return Promise.reject(err.response.data);
                }

            }
            return Promise.reject(err);
        }
    );
    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage/>
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>

            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login"/>
            ) : (
                <Layout>
                    <BasePage/>
                </Layout>
            )}
        </Switch>
    );
}
