import {createSlice} from "@reduxjs/toolkit";

const initialVirtualProductsState = {
    listLoading: false,
    actionsLoading: false,
    total_count: 0,
    entities: null,
    virtualIngestions: null,
    savedSearches: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const virtualproductsSlice = createSlice({
    name: "virtualproducts",
    initialState: initialVirtualProductsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // findVirtualProducts
        virtualproductsFetched: (state, action) => {
            const {total_count, entities} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = entities;
            state.total_count = total_count;
        },

        virtualproductsUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
        },

        virtualproductsListingAdded: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },

        virtualIngestionsFetched: (state, action) => {
            const {total_count, entities} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.virtualIngestions = entities;
        },
        savedSearchesFetched: (state, action) => {
            const {total_count, entities} = action.payload;
            state.listLoading = false;
            state.error = null;
            state.savedSearches = entities;
        },
        savedSearchUpdated: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.savedSearches = state.savedSearches.map(entity => {
                if (entity.id === action.payload.savedSearch.id) {
                    entity = {...entity, ...action.payload.savedSearch}
                }
                return entity;
            });
        },
        savedSearchDeleted: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.savedSearches = state.savedSearches.filter(
                el => action.payload.id !== el.id
            );
        },
        savedSearchAdded: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.savedSearches.push(action.payload.savedSearch);
        },
    }
});
