/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Table} from "react-bootstrap";
import dayjs from "dayjs";

export function LastRepricingActivitiesWidget({className, repriceEvents, region, openPage, goRoute}) {
    return (
        <>
            {region && repriceEvents &&
            <div className={`card card-custom ${className} ribbon ribbon-clip ribbon-left`} style={{minHeight: 450, height: "unset"}}>
                <div className="ribbon-target" style={{top: 15, height: 45}}>
                    <span className="ribbon-inner bg-primary"></span><i className="fa fa-file-alt" style={{color: "white"}}></i>
                </div>
                {/* begin::Header */}
                <div className="card-header border-0 ">
                    <h3 className="card-title font-weight-bolder text-dark ml-5">
                        Last Repricing Activity
                    </h3>
                    <div className="align-items-end">
                        <a onClick={() => goRoute("/app/reprice-activities")} className="btn btn-secondary mt-5">View All Pricing Activity</a>
                    </div>
                </div>
                {/* end::Header */}

                {/* begin::Body */}
                <div className="card-body mt-0 pt-0 pb-0">
                    <Table responsive>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Diff</th>
                            <th>From</th>
                            <th>To</th>
                        </tr>
                        </thead>
                        <tbody>

                        {repriceEvents && Object.keys(repriceEvents).length > 0 && repriceEvents.map((row, index) => (
                            <tr key={`ra-${index}`}>
                                <td><a style={{color: '#3699FF'}}
                                       onClick={() => openPage(row.listing)}
                                       className="text-dark-75 text-hover-primary font-weight-bold font-size-md"
                                >{row.listing.title.substr(0, 50)}{row.listing.title.length > 50 && '..'}</a>
                                    <div className="text-muted font-weight-bold font-size-sm my-1">{dayjs(row.created_at).format('lll')}</div>
                                </td>
                                <td>{(row.to_amount - row.from_amount) > 0 ? (<i className="fa fa-arrow-up text-primary"/>) : (<i className="fa fa-arrow-down text-secondary"/>)} {(row.to_amount - row.from_amount).toLocaleString(region.locale, {style: 'currency', currency: region.currency})}</td>
                                <td>{row.from_amount.toLocaleString(region.locale, {style: 'currency', currency: region.currency})}</td>
                                <td>{row.to_amount.toLocaleString(region.locale, {style: 'currency', currency: region.currency})}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                </div>
                {/* end::Body */}
            </div>
            }
        </>
    );
}
