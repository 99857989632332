import React from "react";

export function NumberWidget({
                                 className,
                                 baseColor = "primary",
                                 widgetHeight = "150px",
                                 iconColor,
                                 faIcon,
                                 number,
                                 text
                             }) {
    return (
        <>
            <div
                className={`card card-custom bg-${baseColor} ${className}`}
                style={{height: widgetHeight}}
            >
                <div className="card-body">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="align-self-start">
                            <span className={`text-inverse-${baseColor} font-weight-bolder `} style={{fontSize: 45}}>
                                {number.toLocaleString('en-US')}
                            </span>
                        </div>
                        <div className="p-2"><span>
            <i className={`fa fa-3x ${faIcon} ${iconColor}`}/>
          </span></div>
                    </div>
                    <div className="d-flex flex-row justify-content-start">
            <span
                className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}
            >
            {text}
          </span>
                    </div>


                </div>
            </div>
        </>
    );
}
