export const ListingStatusCssClasses = {"draft": "info", "active": "success"};
export const ListingStatusTitles = {"draft": "Draft", "active": "Active"};
export const ListingConditionCssClasses = ["success", "danger", ""];
export const ListingConditionTitles = ["New", "Used"];
export const defaultSorted = [{dataField: "id", order: "asc"}];
export const sizePerPageList = [
    {text: "20", value: 20},
    {text: "30", value: 30},
    {text: "50", value: 50},
];
export const initialFilter = {
    filter: {
    },
    sortOrder: "asc", // asc||desc
    sortField: "title",
    pageNumber: 1,
    pageSize: 30
};

export const AMAZON_CATEGORIES = [
    {value: "All", text: "All Categories"},
    {value: "Apparel", text: "Apparel"},
    {value: "Appliances", text: "Appliances"},
    {value: "ArtsAndCrafts", text: "Arts and Crafts"},
    {value: "Automotive", text: "Automotive"},
    {value: "Baby", text: "Baby"},
    {value: "Beauty", text: "Beauty"},
    {value: "Books", text: "Books"},
    {value: "Classical", text: "Classical"},
    {value: "DigitalMusic", text: "Digital Music"},
    {value: "DVD", text: "DVD"},
    {value: "Electronics", text: "Electronics"},
    {value: "Grocery", text: "Grocery"},
    {value: "HealthPersonalCare", text: "Health & Personal Care"},
    {value: "HomeGarden", text: "Home Garden"},
    {value: "Industrial", text: "Industrial"},
    {value: "Jewelry", text: "Jewelry"},
    {value: "KindleStore", text: "Kindle Store"},
    {value: "Kitchen", text: "Kitchen"},
    {value: "Magazines", text: "Magazines"},
    {value: "Miscellaneous", text: "Miscellaneous"},
    {value: "MobileApps", text: "Mobile Apps"},
    {value: "MP3Downloads", text: "Mp3 Downloads"},
    {value: "Music", text: "Music"},
    {value: "MusicalInstruments", text: "Musical Instruments"},
    {value: "OfficeProducts", text: "Office Products"},
    {value: "PCHardware", text: "PC Hardware"},
    {value: "PetSupplies", text: "Pet Supplies"},
    {value: "Photo", text: "Photo"},
    {value: "Shoes", text: "Shoes"},
    {value: "Software", text: "Software"},
    {value: "SportingGoods", text: "Sporting Goods"},
    {value: "Tools", text: "Tools"},
    {value: "Toys", text: "Toys"},
    {value: "UnboxVideo", text: "Unbox Video"},
    {value: "VHS", text: "VHS"},
    {value: "Video", text: "Video"},
    {value: "VideoGames", text: "Video Games"},
    {value: "Watches", text: "Watches"},
    {value: "Wireless", text: "Wireless"},
    {value: "WirelessAccessories", text: "Wireless Accessories"}
];

export const AMAZON_REGIONS = [
    {value: "Canada", text: "Canada"},
    {value: "US", text: "United States"},
    {value: "Mexico", text: "Mexico"},
    {value: "Brazil", text: "Brazil"},
    {value: "Spain", text: "Spain"},
    {value: "UK", text: "United Kingdom"},
    {value: "France", text: "France"},
    {value: "Netherlands", text: "Netherlands"},
    {value: "Germany", text: "Germany"},
    {value: "Italy", text: "Italy"},
    {value: "Sweden", text: "Sweden"},
    {value: "Poland", text: "Poland"},
    {value: "Egypt", text: "Egypt"},
    {value: "Turkey", text: "Turkey"},
    {value: "SaudiArabia", text: "Saudi Arabia"},
    {value: "UnitedArabEmirates", text: "United Arab Emirates"},
    {value: "India", text: "India"},
    {value: "Singapore", text: "Singapore"},
    {value: "Australia", text: "Australia"},
    {value: "Japan", text: "Japan"},
];

export const CURRENCY_LOCALE = {
    Canada: {currency: 'CAD', locale: 'en-CA', base : 'http://amazon.ca/', portal: 'https://sellercentral.amazon.ca/'},
    US: {currency: 'USD', locale: 'en-US', base : 'http://amazon.com/', portal: 'https://sellercentral.amazon.com/'},
    Mexico: {currency: 'MXN', locale: 'es-MX', base : 'https://amazon.com.mx/', portal: 'https://sellercentral.amazon.com.mx/'},
    Brazil: {currency: 'BRL', locale: 'pt-BR', base : 'https://amazon.com.br/', portal: 'https://sellercentral.amazon.com.br/'},
    Spain: {currency: 'EUR', locale: 'es-ES', base : 'https://amazon.es/', portal: 'https://sellercentral-europe.amazon.com/'},
    UK: {currency: 'GBP', locale: 'en-GB', base : 'https://amazon.co.uk/', portal: 'https://sellercentral-europe.amazon.com/'},
    France: {currency: 'EUR', locale: 'fr-FR', base : 'https://amazon.fr/', portal: 'https://sellercentral-europe.amazon.com/'},
    Netherlands: {currency: 'EUR', locale: 'nl-NL', base : 'https://www.amazon.nl/', portal: 'https://sellercentral.amazon.nl/'},
    Germany: {currency: 'EUR', locale: 'de-DE', base : 'https://amazon.de/', portal: 'https://sellercentral-europe.amazon.com/'},
    Italy: {currency: 'EUR', locale: 'it-IT', base : 'https://amazon.it/', portal: 'https://sellercentral-europe.amazon.com/'},
    Sweden: {currency: 'SEK', locale: 'sv-SE', base : 'https://www.amazon.se/', portal: 'https://sellercentral.amazon.se/'},
    Poland: {currency: 'PLN', locale: 'pl-PL', base : 'https://www.amazon.pl/', portal: 'https://sellercentral.amazon.pl/'},
    Egypt: {currency: 'EGP', locale: 'ar-EG', base : 'https://www.amazon.eg/', portal: 'https://sellercentral.amazon.eg/'},
    Turkey: {currency: 'TRY', locale: 'tr-TR', base : 'https://www.amazon.com.tr/', portal: 'https://sellercentral.amazon.com.tr/'},
    SaudiArabia: {currency: 'SAR', locale: 'ar-SA', base : 'https://www.amazon.sa/', portal: 'https://sellercentral.amazon.com.sa/'},
    UnitedArabEmirates: {currency: 'AED', locale: 'ar-AE', base : 'https://amazon.ae/', portal: 'https://sellercentral.amazon.ae/'},
    India: {currency: 'INR', locale: 'hi-IN', base : 'https://amazon.in/', portal: 'https://sellercentral.amazon.in/'},
    Singapore: {currency: 'SGD', locale: 'zh_SG', base : 'https://www.amazon.sg/', portal: 'https://sellercentral.amazon.sg/'},
    Australia: {currency: 'AUD', locale: 'en-AU', base : 'https://www.amazon.com.au/', portal: 'https://sellercentral.amazon.com.au/'},
    Japan: {currency: 'JPY', locale: 'ja-JP', base : 'https://amazon.co.jp/', portal: 'https://sellercentral.amazon.co.jp/'}
};
