import {createSlice} from "@reduxjs/toolkit";

const initialUploadsState = {
  listLoading: false,
  actionsLoading: false,
  total_count: 0,
  entities: null,
  uploadForEdit: undefined,
  error: null,
  authUrl: null
};
export const callTypes = {
  list: "list",
  action: "action"
};
export const uploadsSlice = createSlice({
  name: "uploads",
  initialState: initialUploadsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getUploadById
    uploadFetched: (state, action) => {
      state.actionsLoading = false;
      state.uploadForEdit = action.payload.uploadForEdit;
      state.error = null;
    },
    // findUploads
    uploadsFetched: (state, action) => {
      const { total_count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.total_count = total_count;
    },
    // createUpload
    uploadCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    authUrlFetchCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.authUrl = action.payload.url;
    },
    // updateUpload
    uploadUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.upload.id) {
          entity = {...entity, ...action.payload.upload};
        }
        return entity;
      });
    },
    // deleteUpload
    uploadDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteUploads
    uploadsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // uploadsUpdateState
    uploadsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
