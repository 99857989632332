import React from "react";
import {Modal} from "react-bootstrap";

export function YoutubeModal(videoId, show, onHide) {


    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header style={{justifyContent: 'right'}} closeButton/>
            <Modal.Body>
                <video width="1050" height="590" controls autoPlay>
                  <source src={videoId} type="video/mp4"/>
                </video>
            </Modal.Body>
        </Modal>
    )
}
