/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getUserUiData, updateOnboardChecklistData, updateUserUiData} from "../modules/App/_redux/dashboard/dashboardActions";
import {Modal, ProgressBar} from "react-bootstrap";
import {YoutubeModal} from "./YoutubeModal";

export function OnboardChecklist() {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);
    const [videoId, setVideoId] = useState(false);
    const {ui_data} = useSelector(
        (state) => ({
            ui_data: state.dashboard.ui_data,
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(getUserUiData())
    }, []);

    useEffect(() => {
        if (ui_data.onboard_checklist) {
            let cnt = 0;
            if(ui_data.onboard_checklist.first){
                cnt++;
            }
            if(ui_data.onboard_checklist.second){
                cnt++;
            }
            if(ui_data.onboard_checklist.third){
                cnt++;
            }
            if(cnt === 1){
                setProgressPercent(33)
            } else if(cnt === 2){
                setProgressPercent(66)
            } else if(cnt === 3){
                setProgressPercent(100)
            }
        }
    }, [ui_data]);

    const hideWidget = () => {
        let onboardChecklist = ui_data.onboard_checklist
        dispatch(updateUserUiData({...ui_data, onboard_checklist: {...onboardChecklist, show: false}}))
    }

    const handleOpenVideo = (id) => {
        setVideoId(id);
        setShow(true);
    }

    return (
        <>
            {show && YoutubeModal(videoId, show, () => setShow(false) )}
            {(!ui_data || !ui_data.onboard_checklist || (ui_data && ui_data.onboard_checklist && ui_data.onboard_checklist.show)) && <div className={`card card-custom card-stretch ribbon ribbon-clip ribbon-left`} style={{margin:20, height: "auto"}}>
                <div className="ribbon-target" style={{top: 15, height: 45}}>
                    <span className="ribbon-inner bg-warning"></span><i className="fa fa-star" style={{color: "white"}}></i>
                </div>
                {/* Head */}
                <div className="card-header border-0">
                    <div className="card-title font-weight-bolder text-dark d-flex d-flex-column" style={{minHeight: 50, width: '80%', marginLeft: 20}}>
                        <div className="p-2">Welcome on board! Here is a 3-step guide for you to get started</div>
                        <div className="p-2" style={{width: '50%'}}>
                            <div className="d-flex flex-row">
                                <div><i>({progressPercent}% Completed)</i></div>
                                <div style={{width: '50%', marginLeft: 10, marginTop:5}}><ProgressBar variant="primary" animated={true} now={progressPercent} min="0" max="100" striped/></div>
                            </div>
                        </div>
                    </div>

                    {ui_data.onboard_checklist && <div className="card-toolbar"><a className="btn btn-light btn-sm" onClick={() => hideWidget()}>Hide this forever</a></div>}
                </div>
                {/* Body */}
                <div className="card-body" style={{marginLeft: 20, padding: 0}}>
                    <div className="d-flex align-items-center mb-5">
                        <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                        <div className="d-flex flex-column flex-grow-1 ml-5">
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column">
                                    <a onClick={() => handleOpenVideo('https://saledrum.com/wp-content/uploads/2022/08/SaleDrum-Onboarding-Update-minimum-and-maximum-price-of-a-product-HD-1080p.mov')} style={{marginRight: 10, marginTop: 5}}>
                                        <img src="/media/misc/playbutton.png" style={{borderWidth: 1, borderStyle: 'solid', borderColor: '#CCCCCC', width: 80}}/>
                                    </a>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="font-size-h2 mt-3 font-weight-bold text-success">STEP 1</div>
                                </div>
                                <div className="d-flex flex-column ml-3">
                                    { ui_data.onboard_checklist && ui_data.onboard_checklist.first ? (<span
                                        className="text-dark-75 font-weight-bold font-size-h4 mb-1 mt-4 text-muted"
                                    >
                                        <del>Go to "<strong>My Electronic Store</strong>" demo channel and edit min and max price of "<strong>New Apple AirPods Pro</strong>"</del>
                                    </span>) : (
                                    <span
                                        className="text-dark-75 font-weight-bold font-size-h4 mb-1 mt-4"
                                    >
                                        Go to "<strong>My Electronic Store</strong>" demo channel and edit min and max price of "<strong>New Apple AirPods Pro</strong>"
                                    </span>)}
                                    {/*<span className="text-muted font-weight-bold">Alternatively you can do that manually by setting Min and Max price values for each listing. This is required to activate automatic pricing</span>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center mb-5">
                        <span className="bullet bullet-bar bg-primary align-self-stretch"></span>

                        <div className="d-flex flex-column flex-grow-1 ml-5">
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column">
                                    <a onClick={() => handleOpenVideo('https://saledrum.com/wp-content/uploads/2022/08/SaleDrum-Onboarding-Assign-a-reprice-rule-to-products-HD-1080p.mov')} style={{marginRight: 10, marginTop: 5}}>
                                        <img src="/media/misc/playbutton.png" style={{borderWidth: 1, borderStyle: 'solid', borderColor: '#CCCCCC', width: 80}}/>
                                    </a>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="font-size-h2 mt-3 font-weight-bold text-primary">STEP 2</div>
                                </div>
                                <div className="d-flex flex-column ml-3">
                                    { ui_data.onboard_checklist && ui_data.onboard_checklist.second ? (<span
                                        className="text-dark-75 font-weight-bold font-size-h4 mb-1 mt-4 text-muted"
                                    >
                                        <del>Assign "<strong>qty &lt; 10, max price - 10%</strong>" pricing rule to the first product in "<strong>My Electronic Store</strong>"</del>
                                    </span>) : (
                                    <span
                                        className="text-dark-75 font-weight-bold font-size-h4 mb-1 mt-4"
                                    >
                                        Assign "<strong>qty &lt; 10, max price - 10%</strong>" pricing rule to the first product in "<strong>My Electronic Store</strong>"
                                    </span> )}
                                    {/*<span className="text-muted font-weight-bold">We have 3 types of repricing rules: Stock-based, BuyBox Winner and Competitor Product based</span>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center mb-5">
                        <span className="bullet bullet-bar bg-warning align-self-stretch"></span>

                        <div className="d-flex flex-column flex-grow-1 ml-5">
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column">
                                    <a onClick={() => handleOpenVideo('https://saledrum.com/wp-content/uploads/2022/08/SaleDrum-Onboarding-Activate-channels-HD-1080p.mov')} style={{marginRight: 10, marginTop: 5}}>
                                        <img src="/media/misc/playbutton.png" style={{borderWidth: 1, borderStyle: 'solid', borderColor: '#CCCCCC', width: 80}}/>
                                    </a>
                                </div>
                                 <div className="d-flex flex-column">
                                    <div className="font-size-h2 mt-3 font-weight-bold text-warning">STEP 3</div>
                                </div>
                                <div className="d-flex flex-column ml-3">
                                    { ui_data.onboard_checklist && ui_data.onboard_checklist.third ? (<span
                                        className="text-dark-75 font-weight-bold font-size-h4 mb-1 mt-4 text-muted"
                                    >
                                        <del>Enable <strong>repricing</strong> and <strong>live</strong> status of your channel on the channels page and let the magic happen!</del>
                                    </span>) : (
                                    <span
                                        className="text-dark-75 font-weight-bold font-size-h4 mb-1 mt-4"
                                    >
                                        Enable <strong>repricing</strong> and <strong>live</strong> status of your channel on the channels page and let the magic happen!
                                    </span>)}
                                    {/*<span className="text-muted font-weight-bold">No changes will be made on your listings before you do that. You can pause it any time.</span>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}