import {createSlice} from "@reduxjs/toolkit";

const initialdashboardState = {
  listLoading: false,
  actionsLoading: false,
  total_count: 0,
  entities: null,
  user_activity: null,
  lastError: null,
  paymentPage: null,
  ui_data: {
    onboard_checklist: {
      show: true,
      first: false,
      second: false,
      third: false
    }
  }
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialdashboardState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    userActivityFetched: (state, action) => {
      state.actionsLoading = false;
      state.userActivity = action.payload;
      state.error = null;
    },
    statsFetched: (state, action) => {
      state.actionsLoading = false;
      state.stats = action.payload;
      state.error = null;
    },
    paymentPageFetched: (state, action) => {
      state.actionsLoading = false;
      state.paymentPage = action.payload;
      state.error = null;
    },
    uiDataFetched: (state, action) => {
      state.actionsLoading = false;
      if (action.payload !== null && Object.keys(action.payload).length > 0) {
        state.ui_data = action.payload;
      }
      state.error = null;
    },
    uiDataUpdated: (state, action) => {
      state.actionsLoading = false;
      state.ui_data = {...state.ui_data, [action.payload.field] : action.payload.data};
      state.error = null;
    }
  }
});
