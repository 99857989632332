import React, {useRef, useState} from "react";
import {useFormik} from "formik";
import {connect} from "react-redux";
import * as Yup from "yup";
import {Link} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {register} from "../_redux/authCrud";
import {Mixpanel} from "../../../common/Mixpanel";
import Recaptcha from "react-google-invisible-recaptcha";

const initialValues = {
    invitation_code: "",
    first_name: "",
    last_name: "",
    email: "",
    acceptTerms: false,
    captcha: ""
};

function Registration(props) {
    const {intl} = props;
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const refRecaptcha = useRef(null);
    const RegistrationSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        last_name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        acceptTerms: Yup.bool().required(
            "You must accept the terms and conditions"
        ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setSubmitting(true);
            enableLoading();
            setTimeout(() => {
                register(values.email, values.first_name, values.last_name, values.captcha)
                    .then(({data: {refresh_token, access_token, user_id}}) => {
                        disableLoading();
                        Mixpanel.identify(user_id);
                        Mixpanel.track('Successful registration');
                        Mixpanel.people.set({
                            $first_name: values.first_name,
                            $last_name: values.last_name,
                            $email: values.email,
                        });
                        setSuccess(values.email)
                    })
                    .catch((err) => {
                        refRecaptcha.current.callbacks.reset();
                        refRecaptcha.current.callbacks.execute();
                        values.captcha = refRecaptcha.current.callbacks.getResponse();
                        let msg = 'Can not register at this time. Please try again later';
                        if (Array.isArray(err?.response?.data?.error_message)) {
                            msg = err.response.data.error_message[0]
                        } else if (err?.response?.data?.error_message) {
                            msg = err.response.data.error_message
                        } else if (err?.response?.data?.message) {

                            if (err.response.data.message === 'Wrong token') {
                                msg = 'Your session is expired. Plaease log back in'
                            } else {
                                msg = err.response.data.message
                            }
                        }
                        disableLoading();
                        setSubmitting(false);
                        setStatus(
                            msg
                        );
                        disableLoading();
                        Mixpanel.track('Failed registration', {
                            'email': values.email,
                            'error': msg
                        });
                    });
            }, 1000);
        },
    });
    const handleBlur2 = (e) => {
        if (refRecaptcha?.current?.callbacks && !formik.values.captcha) {
            refRecaptcha.current.callbacks.execute();
            formik.setSubmitting(true);
        }
        formik.handleBlur(e);
    }
    return (
        <div className="login-form login-signin" style={{display: "block"}}>
            {success && <div className="text-center mb-10 mb-lg-20">
                <h2>You're almost done !</h2>
                <h4 className="font-size-h4">
                    We've sent a verification email to {success}
                </h4>
                <h4 className="font-size-h4 mt-10">
                    Check you email box and click on the verification link to create your password
                </h4>
            </div>}
            {!success && <>
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">
                        <FormattedMessage id="AUTH.REGISTER.TITLE"/>
                    </h3>
                    <p className="text-muted font-weight-bold">
                        Enter your details to create your account
                    </p>
                </div>

                <form
                    id="kt_login_signin_form"
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={formik.handleSubmit}
                >
                    {/* begin: Alert */}
                    {formik.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    )}
                    {/* end: Alert */}

                    {/* begin: First name */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="First name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "first_name"
                            )}`}
                            name="first_name"
                            {...formik.getFieldProps("first_name")}
                            onBlur={handleBlur2}
                        />
                        {formik.touched.first_name && formik.errors.first_name ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.first_name}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Last name */}

                    {/* begin: Last name */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Last name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "last_name"
                            )}`}
                            name="first_name"
                            {...formik.getFieldProps("last_name")}
                            onBlur={handleBlur2}
                        />
                        {formik.touched.last_name && formik.errors.last_name ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.last_name}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Last name */}

                    {/* begin: Email */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Email"
                            type="email"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formik.getFieldProps("email")}
                            onBlur={handleBlur2}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Email */}
                    <div className="form-group d-flex flex-wrap flex-center">
                        <Recaptcha ref={refRecaptcha} sitekey={process.env.REACT_APP_CAPTCHA_TOKEN} onExpired={() => {
                            refRecaptcha.current.callbacks.reset();
                            refRecaptcha.current.callbacks.execute();
                            formik.setFieldValue("captcha", refRecaptcha.current.callbacks.getResponse());
                        }} onResolved={() => {
                            formik.setFieldValue("captcha", refRecaptcha.current.callbacks.getResponse());
                            formik.setSubmitting(false);
                        }}/>
                        <button
                            type="submit"
                            // disabled={
                            //     formik.isSubmitting ||
                            //     !formik.isValid ||
                            //     !formik.values.acceptTerms
                            // }
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                        >
                            <span>Submit</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>

                        <Link to="/auth/login">
                            <button
                                type="button"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            >
                                Cancel
                            </button>
                        </Link>
                    </div>
                    <div className="text-center">
                        <span className="font-weight-bold text-dark-50">Have an account?</span>
                        <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup">Log In!</Link>
                    </div>
                </form>
            </>}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Registration));
