/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {KTUtil} from "../../_metronic/_assets/js/components/util";
import {useHtmlClassService} from "../../_metronic/layout";

export function RepriceActivityWidget({className, symbolShape, baseColor, data, totalActivity, title}) {

    useEffect(() => {
        const element = document.getElementById("kt_stats_widget_11_chart");

        if (!element || !data) {
            return;
        }
        const height = parseInt(KTUtil.css(element, "height"));
        const options = getChartOption(height + 100, data);
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [data]);

    return ( totalActivity && title && baseColor && className &&
        <>
            {/* begin::Stats Widget 10 */}
            <div className={`card card-custom ${className}`} style={{minHeight: 450}}>
                <div className="card-header border-0 align-items-center">
                    <h3 className="card-title font-weight-bolder text-dark">
                        {title}
                    </h3>
                    <span className="text-dark-75 font-weight-bolder font-size-h2  ml-10">
                                  {totalActivity.toLocaleString('en-US')}
                              </span>
                </div>
                {/* begin::Body */}
                <div className="card-body p-0">
                    <div
                        id="kt_stats_widget_11_chart"
                        className="card-rounded-bottom"
                        data-color={baseColor}
                        style={{height: "250px"}}
                    />
                </div>
                {/* end::Body */}
            </div>
            {/* end::Stats Widget 10 */}
        </>
    );
}

function getChartOption(height, data) {
    return {
        series: [{
            name: 'Total Reprice Events',
            data: data
        }],
        chart: {
            type: 'bar',
            stacked: false,
            height: height,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        title: {
            text: '',
            align: 'left'
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
            title: {
                text: 'Daily Repricing Events'
            },
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        }

    };
}
