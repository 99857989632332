import {createSlice} from "@reduxjs/toolkit";

const initialProductInventoriesState = {
  listLoading: false,
  actionsLoading: false,
  total_count: 0,
  entities: null,
  productInventoryForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const productInventoriesSlice = createSlice({
  name: "productInventories",
  initialState: initialProductInventoriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProductInventoryById
    productInventoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.productInventoryForEdit = action.payload.productInventoryForEdit;
      state.error = null;
    },
    // findProductInventories
    productInventoriesFetched: (state, action) => {
      const { total_count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.total_count = total_count;
    },
    // createProductInventory
    productInventoryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.productInventory);
    },
    // updateProductInventory
    productInventoryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.productInventory.id) {
          entity = {...entity, ...action.payload.productInventory};
        }
        return entity;
      });
    },
    // deleteProductInventory
    productInventoryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteProductInventories
    productInventoriesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
