import {createSlice} from "@reduxjs/toolkit";

const initialPricerulesState = {
  listLoading: false,
  actionsLoading: false,
  total_count: 0,
  entities: null,
  priceruleForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const pricerulesSlice = createSlice({
  name: "pricerules",
  initialState: initialPricerulesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.listLoading = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPriceruleById
    priceruleFetched: (state, action) => {
      state.actionsLoading = false;
      state.priceruleForEdit = action.payload.priceruleForEdit;
      state.error = null;
    },
    // findPricerules
    pricerulesFetched: (state, action) => {
      const { total_count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.total_count = total_count;
    },
    // createPricerule
    priceruleCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updatePricerule
    priceruleUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      if(state.entities) {
        state.entities = state.entities.map(entity => {
          if (entity.id === action.payload.pricerule.id) {
            entity = {...entity, ...action.payload.pricerule}
          }
          return entity;
        });
      }
    },
    // deletePricerule
    priceruleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deletePricerules
    pricerulesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    }
  }
});
