import * as requestFromServer from "./dashboardCrud";
import {dashboardSlice, callTypes} from "./dashboardSlice";

const {actions} = dashboardSlice;

export const fetchAccountUserActivity = () => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .getUserActivity()
        .then(response => {
            dispatch(actions.userActivityFetched(response.data));
            return Promise.resolve();
        })
        .catch(error => {
            error.clientMessage = "Can't find user activity";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return Promise.reject(error);
        });
};

export const fetchDashboardStats = () => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .getDashboardStats()
        .then(response => {
            dispatch(actions.statsFetched(response.data));
            return Promise.resolve();
        })
        .catch(error => {
            error.clientMessage = "Can't fetch dashboard data";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return Promise.reject(error);
        });
};

export const updateUserUiData = (ui_data) => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .updateUserUiData(ui_data)
        .then(response => {
            dispatch(actions.uiDataFetched(ui_data));
            return Promise.resolve();
        })
        .catch(error => {
            error.clientMessage = "Can't update user ui data";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return Promise.reject(error);
        });
};

export const updateOnboardChecklistData = (onboard_checklist) => dispatch => {
    dispatch(actions.uiDataUpdated({field: 'onboard_checklist', data:onboard_checklist}));
};

export const getUserUiData = () => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .getUserUiData()
        .then(response => {
            dispatch(actions.uiDataFetched(response.data.ui_data));
            return Promise.resolve();
        })
        .catch(error => {
            error.clientMessage = "Can't get user ui data";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return Promise.reject(error);
        });
};

export const getPaymentPage = () => dispatch => {
    dispatch(actions.startCall({callType: callTypes.action}));
    return requestFromServer
        .getPaymentPage()
        .then(response => {
            dispatch(actions.paymentPageFetched(response.data.url));
            return Promise.resolve();
        })
        .catch(error => {
            error.clientMessage = "Can't get payment page";
            dispatch(actions.catchError({error, callType: callTypes.action}));
            return Promise.reject(error);
        });
};