/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useContext} from "react";
import {useLocation} from "react-router";
import {NavLink, useHistory} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {shallowEqual, useSelector} from "react-redux";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import {SupportFormContext} from "../../../../../app/pages/SupportFormProvider";

export function AsideMenuList({layoutProps}) {
    const location = useLocation();
    const history = useHistory();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-open menu-item-not-hightlighted`
            : "";
    };
    const {mychannels, email, discovery, is_pro, is_demo} = useSelector(
        (state) => ({
            mychannels: state.auth.user.account.channels,
            email: state.auth.user.email,
            discovery: state?.auth?.user?.account?.features_enabled?.discovery ? state.auth.user.account.features_enabled.discovery : false,
            is_pro: state?.auth?.user?.account?.is_pro ? state.auth.user.account.is_pro : false,
            is_demo: state?.auth?.user?.account?.is_demo ? state.auth.user.account.is_demo : false,
        }),
        shallowEqual
    );
    const goToUrl = (url) => {
        history.push(url);
    }
    const {showSupportModal, hideSupportModal} = useContext(SupportFormContext);
    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
                        </span>
                        <span className="menu-text">Dashboard</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}
                {(mychannels && mychannels.length == 1) ? (
                    <li
                        className={`menu-item ${getMenuItemActive(`/app/listings/${mychannels[0].channel_type}/${mychannels[0].id}`, false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={`/app/listings/${mychannels[0].channel_type}/${mychannels[0].id}`}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")}/>
                        </span>
                            <span className="menu-text">Manage Listings</span>
                        </NavLink>
                    </li>) : (mychannels && mychannels.length > 1) ?
                    (<li
                        className={`menu-item menu-item-submenu ${getMenuItemActive("/app/listings", true)}`}
                        aria-haspopup="true" data-menu-toggle="hover">
                        <NavLink className="menu-link menu-toggle" to="/app/listings">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")}/>
                        </span>
                            <span className="menu-text">Manage Listings</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                    <span className="menu-text">Manage Listings</span>
                                </span>
                                </li>
                                {!mychannels && (
                                    <li className={`menu-item ${getMenuItemActive("/app/channels/amazon")}`} aria-haspopup="true">
                                        <NavLink className="menu-link" to="/app/channels/amazon">
                                            <i className="menu-bullet menu-bullet-dot">
                                                <span/>
                                            </i>
                                            <span className="menu-text">Add channel</span>
                                        </NavLink>
                                    </li>
                                )}
                                {mychannels && mychannels.map((channel) => (
                                    <li className={`menu-item ${getMenuItemActive("/app/listings/" + channel.channel_type + "/" + channel.id)}`} aria-haspopup="true" key={channel.id}>
                                        <NavLink className="menu-link" to={'/app/listings/' + channel.channel_type + '/' + channel.id}>
                                            <Chip
                                                style={{fontSize: 14, fontWeight: 500, cursor: 'pointer'}}
                                                key={channel.id}
                                                avatar={<Avatar src={'/media/channels/' + channel.channel_type + '.png'}/>}
                                                label={channel.name}
                                            />
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/*end::2 Level*/}
                    </li>) : (
                        <li
                            className={`menu-item ${getMenuItemActive("/app/channels/import-from-channels", false)}`}
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/app/channels/import-from-channels">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}/>
                        </span>
                                <span className="menu-text">Add Amazon Channel</span>
                            </NavLink>
                        </li>)}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive("/app/orders", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/app/orders">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Safe.svg")}/>
                        </span>
                        <span className="menu-text">Orders</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/app/pricerules", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/app/pricerules">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/CPU1.svg")}/>
                        </span>
                        <span className="menu-text">Repricing Rules</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/app/reprice-activities", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/app/reprice-activities">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")}/>
                        </span>
                        <span className="menu-text">Repricing Activity</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/app/uploads/new", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/app/uploads/new">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}/>
                        </span>
                        <span className="menu-text">Uploads</span>
                    </NavLink>
                </li>

                {discovery && <li
                    className={`menu-item ${getMenuItemActive("/app/virtual-products", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/app/virtual-products">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode-scan.svg")}/>
                        </span>
                        <span className="menu-text">Discovery</span>
                    </NavLink>
                </li>}

                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                {/*<li*/}
                {/*    className={`menu-item ${getMenuItemActive("/app/reports", false)}`}*/}
                {/*    aria-haspopup="true"*/}
                {/*>*/}
                {/*    <NavLink className="menu-link" to="/app/reports">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-pie.svg")}/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text">Reports</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                {/*end::1 Level*/}
                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">Settings</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/app/users", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/app/users">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}/>
                        </span>
                        <span className="menu-text">User Management</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/app/channels", false)}`}
                    aria-haspopup="true">
                    <NavLink className="menu-link" to="/app/channels">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Shuffle.svg")}/>
                        </span>
                        <span className="menu-text">Sales Channels</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive("/app/settings", true)}`}
                    aria-haspopup="true" data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/app/settings">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}/>
                        </span>
                        <span className="menu-text">Account Settings</span>
                        <i className="menu-arrow"/>
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow"/>
                        <ul className="menu-subnav">
                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                    <span className="menu-text">Account</span>
                                </span>
                            </li>
                            <li className={`menu-item ${getMenuItemActive("/user-profile/personal-information")}`} aria-haspopup="true">
                                <NavLink className="menu-link" to="/user-profile/personal-information">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">Profile</span>
                                </NavLink>
                            </li>
                            <li className={`menu-item ${getMenuItemActive("/user-profile/change-password")}`} aria-haspopup="true">
                                <NavLink className="menu-link" to="/user-profile/change-password">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span/>
                                    </i>
                                    <span className="menu-text">Change Password</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>

                {/* <li*/}
                {/*    className={`menu-item ${getMenuItemActive("/app/integrations", false)}`}*/}
                {/*    aria-haspopup="true">*/}
                {/*    <NavLink className="menu-link" to="/app/integrations">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Exchange.svg")}/>*/}
                {/*        </span>*/}
                {/*        <span className="menu-text">Integrations</span>*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                {is_pro && !is_demo && <li
                    className={`menu-item ${getMenuItemActive("/app/upgrade", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/app/upgrade">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Update.svg")}/>
                        </span>
                        <span className="menu-text">Change Plan</span>
                    </NavLink>
                </li>}
                <li
                    className={`menu-item`}
                    aria-haspopup="true">
                    <NavLink className="menu-link" to="#" onClick={() => showSupportModal(`email=${email}`)}>
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Urgent-mail.svg")}/>
                        </span>
                        <span className="menu-text">Request Support</span>
                    </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/logout", false)}`}
                    aria-haspopup="true">
                    <NavLink className="menu-link" to="/logout">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")}/>
                        </span>
                        <span className="menu-text">Logout</span>
                    </NavLink>
                </li>
                {!is_pro && !is_demo && <li
                    className={`menu-item`}
                    aria-haspopup="true">
                    <NavLink className="menu-link bg-warning font-weight-bold text-white" to="/app/upgrade">
                        {/*<span className="svg-icon menu-icon  ">*/}
                        {/*    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")}/>*/}
                        {/*</span>*/}
                        <span className="menu-text text-white">Upgrade to Pro</span>
                    </NavLink>
                </li>}
                {/*<li className="menu-section ">*/}
                {/*    <a className="btn btn-secondary btn-block font-weight-bold" onClick={() => showSupportModal(`email=${email}`)}><i className="fa fa-life-ring"/>Request Support</a>*/}
                {/*</li>*/}
                {/*{!is_pro && !is_demo && <li className="menu-section mt-2">*/}
                {/*    <a className="btn btn-warning btn-block font-weight-bold" onClick={() => goToUrl('/app/upgrade')}><i className="fa fa-rocket"/> Upgrade Plan</a>*/}
                {/*</li>}*/}
            </ul>

        </>
    );
}
