import React, { createContext, useState, useCallback } from 'react';
import {SupportForm} from "./SupportForm";

const SupportFormContext = createContext();

const SupportFormProvider = ({ children }) => {
  const [modalShow, setModalShow] = useState(false);
  const [requestParams, setRequestParams] = useState('');

  const showSupportModal = useCallback((content) => {
    setRequestParams(content);
    setModalShow(true);
  }, []);

  const hideSupportModal = useCallback(() => {
    setModalShow(false);
    setRequestParams(null);
  }, []);

  return (
    <SupportFormContext.Provider value={{ showSupportModal, hideSupportModal }}>
      {children}
      <SupportForm onHide={hideSupportModal} show={modalShow} requestParams={requestParams}/>
    </SupportFormContext.Provider>
  );
};

export { SupportFormProvider, SupportFormContext };
