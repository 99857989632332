import React from "react";
import {Button, Modal} from "react-bootstrap";
import {updateUserUiData} from "../modules/App/_redux/dashboard/dashboardActions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export function DemoOrIntegrateModal() {
    const dispatch = useDispatch();
    const history = useHistory();
    const {ui_data} = useSelector(
        (state) => ({
            ui_data: state.dashboard.ui_data,
        }),
        shallowEqual
    );
    const hideDemoOrIntegrateModal = () => {
        dispatch(updateUserUiData({...ui_data, show_demo_or_integrate: false}))
    }
    const integrateWithAmazon = () => {
        dispatch(updateUserUiData({...ui_data, show_demo_or_integrate: false}))
        history.push("/app/channels/import-from-channels")
    }
    return (
        <Modal
            show={ui_data?.show_demo_or_integrate ? ui_data?.show_demo_or_integrate : false}
            size="xl"
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            backdrop="static"
        >
            <Modal.Header><h3>Welcome to SaleDrum! How would you like to use the app? </h3></Modal.Header>
            <Modal.Body>
                <div>
                    <Button variant="primary" size="lg" block onClick={() => hideDemoOrIntegrateModal()}>
                        Explore SaleDrum features with demo data and integrate with your Amazon account later
                    </Button>
                    <Button variant="warning" size="lg" block onClick={() => integrateWithAmazon()}>
                        Integrate with your Amazon account now at no cost
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
