import axios from "axios";

export const LOGIN_URL = "login";
export const PROFILE_URL = "profile";
export const UPDATE_PASSWORD_URL = "password";
export const REGISTER_URL = "register";
export const REQUEST_PASSWORD_URL = "forgot-password";
export const FORGOT_PASSWORD_VERIFY_URL = "email-verification";

export const ME_URL = "me";

export function login(email, password, captcha) {
  return axios.create().post(LOGIN_URL, { email, password, captcha });
}

export function forgotPasswordVerify(password_token, password, captcha) {
  return axios.create().post(FORGOT_PASSWORD_VERIFY_URL, { password_token, password, captcha });
}

export function updateProfile(profile) {
  return axios.put(PROFILE_URL, profile);
}

export function updatePassword(profile) {
  return axios.put(UPDATE_PASSWORD_URL, profile);
}

export function register(email, first_name, last_name, captcha) {
  return axios.create().post(REGISTER_URL, { email, first_name, last_name, captcha});
}

export function requestPassword(email, captcha) {
  return axios.create().post(REQUEST_PASSWORD_URL, { email, captcha });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function turnoffDemo() {
  return axios.post(`turn-off-demo`, {});
}
