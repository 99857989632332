import mixpanel from 'mixpanel-browser';
const {REACT_APP_MIXPANEL_TOKEN, NODE_ENV} = process.env;
mixpanel.init(REACT_APP_MIXPANEL_TOKEN);

let env_check = NODE_ENV === 'production';
// let env_check = true;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  track_links: (name, text, props) => {
    if (env_check) mixpanel.track_links(name, text, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;