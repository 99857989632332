import React, {useEffect} from "react";
import {NumberWidget} from "./NumberWidget";
import {ActivityWidget} from "./ActivityWIdget";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchDashboardStats} from "../modules/App/_redux/dashboard/dashboardActions";
import {useHistory, useLocation} from "react-router-dom";
import {Mixpanel} from "../common/Mixpanel";
import {actions} from "../modules/Auth";
import {useSnackbar} from "notistack";
import {TotalSalesWidget} from "./TotalSalesWidget";
import {BestSellersWidget} from "./BestSellersWidget";
import {LastRepricingActivitiesWidget} from "./LastRepricingActivitiesWidget";
import {CURRENCY_LOCALE} from "../modules/App/pages/listings/ListingsUIHelpers";
import {MostCompetitiveWidget} from "./MostCompetitiveWidget";
import {RepriceActivityWidget} from "./RepriceActivityWidget";
import {OnboardChecklist} from "./OnboardChecklist";

export function SaleDrumDashboard() {
    const dispatch = useDispatch();
    const search = useLocation().search;
    const stripeSuccess = new URLSearchParams(search).get('stripesuccess');
    const stripeFail = new URLSearchParams(search).get('stripefail');
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const {stats, user, mychannels} = useSelector(
        (state) => ({
            stats: state.dashboard.stats,
            user: state.auth.user,
            mychannels: state.auth.user.account.channels,
        }),
        shallowEqual
    );
    const {user_id, account_id, isDemo} = useSelector(
        (state) => ({
            user_id: state.auth.user.id,
            account_id: state.auth.user.account.id,
            isDemo: state.auth.user.account.is_demo,
        }),
        shallowEqual
    );

    const openPage = (listing) => {
        history.push(`/app/listings/${listing.channel_type}/${listing.channel_id}/${listing.id}/edit`);
    }

    useEffect(() => {
        if (stripeSuccess !== null && stripeSuccess === '1') {
            Mixpanel.identify(user_id);
            Mixpanel.track('Pro account is added', {
                'account_id': account_id,
            });
            dispatch(actions.requestUser(user))
            enqueueSnackbar("Your upgrade is completed successfully!", {
                preventDuplicate: true,
                variant: 'success',
                autoHideDuration: 5000,
            });
            setTimeout(() => window.location.replace('/dashboard'), 5000);
        }
        if (stripeFail !== null && stripeFail === '1') {
            Mixpanel.identify(user_id);
            Mixpanel.track('Pro upgrade is failed', {
                'account_id': account_id,
            });
            enqueueSnackbar("Your upgrade failed, please try again", {
                preventDuplicate: true,
                variant: 'error',
                autoHideDuration: 5000,
            });
            setTimeout(() => window.location.replace('/dashboard'), 5000);
        }
    }, [stripeSuccess, stripeFail]);

    useEffect(() => {
        dispatch(fetchDashboardStats())
    }, []);

    const goRoute = (route) => {
        history.push(route);
    }
    return (
        <>
            {(isDemo !== null && isDemo === true) && <OnboardChecklist/>}
            {mychannels && <>
            {stats && <div className="row">
                <div className="col-lg-3 col-xs-12 col-sm-6 col-md-6">
                    <a onClick={() => goRoute("/app/reprice-activities")}><NumberWidget className="gutter-b" baseColor="primary" widgetHeight="150px" iconColor="text-white" faIcon="fa-list" text="repricing events" number={stats.repricing_events_this_month}/></a>
                </div>
                <div className="col-lg-3 col-xs-12 col-sm-6 col-md-6">
                    {mychannels && (mychannels.length > 0) ?
                        (<a onClick={() => goRoute(`/app/listings/${mychannels[0].channel_type}/${mychannels[0].id}`)}><NumberWidget className="gutter-b" baseColor="secondary" widgetHeight="150px" iconColor="text-black" faIcon="fa-store" text="total listings" number={stats.total_listings}/></a>)
                        :
                        (<NumberWidget className="gutter-b" baseColor="secondary" widgetHeight="150px" iconColor="text-black" faIcon="fa-store" text="total listings" number={stats.total_listings}/>)
                    }
                </div>
                <div className="col-lg-3 col-xs-12 col-sm-6 col-md-6">
                    {mychannels && (mychannels.length > 0) ?
                        (<a onClick={() => goRoute(`/app/listings/${mychannels[0].channel_type}/${mychannels[0].id}?qParams=${btoa(JSON.stringify({filter: {is_buy_box_winner: true, channel_type: "amazon", channel_id: mychannels[0].id}}))}`)}><NumberWidget className="gutter-b"
                                                                                                                                                                                                                                                                baseColor="info"
                                                                                                                                                                                                                                                                widgetHeight="150px"
                                                                                                                                                                                                                                                                iconColor="text-white"
                                                                                                                                                                                                                                                                faIcon="fa-check-circle"
                                                                                                                                                                                                                                                                text="buybox winners"
                                                                                                                                                                                                                                                                number={stats.buybox_winners}/></a>)
                        :
                        (<NumberWidget className="gutter-b" baseColor="info" widgetHeight="150px" iconColor="text-white" faIcon="fa-check-circle" text="buybox winners" number={stats.buybox_winners}/>)
                    }
                </div>
                <div className="col-lg-3 col-xs-12 col-sm-6 col-md-6">
                    {mychannels && (mychannels.length > 0) ?
                        (<a onClick={() => goRoute(`/app/listings/${mychannels[0].channel_type}/${mychannels[0].id}?qParams=${btoa(JSON.stringify({filter: {is_lowest_seller: true, channel_type: "amazon", channel_id: mychannels[0].id}}))}`)}>
                            <NumberWidget className="gutter-b" baseColor="primary" widgetHeight="150px" iconColor="text-white" faIcon="fa-angle-double-down" text="at lowest price" number={stats.listing_at_lowest_price}/></a>)
                        :
                        (<NumberWidget className="gutter-b" baseColor="primary" widgetHeight="150px" iconColor="text-white" faIcon="fa-angle-double-down" text="at lowest price" number={stats.listing_at_lowest_price}/>)
                    }
                </div>
            </div>}
            <div className="row">
                {stats && stats.stats && stats.stats.daily_sales && (stats.stats.daily_sales.length > 0) ?
                <div className="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                    <TotalSalesWidget className="gutter-b"
                                      title="Total Monthly Sales"
                                      data={stats.stats.daily_sales}
                                      totalSales={stats.stats.total_sales}
                                      symbolShape="circle"
                                      region={mychannels[0] && mychannels[0].region ? CURRENCY_LOCALE[mychannels[0].region] : CURRENCY_LOCALE['US']}
                                      baseColor="info"/>
                </div> : ''}
                {stats && stats.stats && stats.stats.best_sellers && (stats.stats.best_sellers.length > 0) ?
                <div className="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                    <BestSellersWidget className="gutter-b card-stretch" bestSellers={stats.stats.best_sellers} goRoute={goRoute} region={mychannels[0] && mychannels[0].region ? CURRENCY_LOCALE[mychannels[0].region] : CURRENCY_LOCALE['US']}/>
                </div>: ''}
            </div>

            {mychannels && stats && stats.activity_stats.daily_activity && (stats.activity_stats.daily_activity.length) > 0 &&
            <div className="row">
                <div className="col-lg-5 col-md-6 col-xs-12 col-sm-6">
                    <LastRepricingActivitiesWidget className="gutter-b card-stretch" repriceEvents={stats.stats.reprice_events} region={mychannels[0] && mychannels[0].region ? CURRENCY_LOCALE[mychannels[0].region] : CURRENCY_LOCALE['US']} openPage={openPage} goRoute={goRoute}/>
                </div>
                <div className="col-lg-3 col-md-6 col-xs-12 col-sm-6">
                    <RepriceActivityWidget className="gutter-b"
                                           title="Repricing Events"
                                           data={stats.activity_stats.daily_activity}
                                           totalActivity={stats.activity_stats.total_activity}
                                           symbolShape="circle"
                                           baseColor="info"/>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-12 col-sm-6">
                    <MostCompetitiveWidget className="gutter-b card-stretch" bestSellers={stats.activity_stats.most_competitive} goRoute={goRoute}/>
                </div>
            </div>
            }
            <div className="row">
                <div className="col-12">
                    <ActivityWidget className="card-stretch gutter-b"/>
                </div>
            </div>
        </>}</>
    );
}
