import axios from "axios";

export const USER_ACTIVIY_URL = "user-activities";
export const PROFILE_URL = "profile";
export const DASHBOARD_STATS_URL = "stats/dashboard";
export const PAYMENT_PAGE_URL = "payment-url";


export function getUserActivity() {
  return axios.get(`${USER_ACTIVIY_URL}`, {params : {sort: '["created_at" , "DESC"]', range: '[0,9]' }});
}

export function getDashboardStats() {
  return axios.get(`${DASHBOARD_STATS_URL}`, {});
}

export function updateUserUiData(ui_data) {
  return axios.put(`${PROFILE_URL}`, {ui_data});
}

export function getUserUiData(ui_data) {
  return axios.get(`${PROFILE_URL}`, {});
}
export function getPaymentPage(page_id) {
  let config = {}
  if(page_id){
    config = {params: {page_id}}
  }
  return axios.get(`${PAYMENT_PAGE_URL}`, config);
}