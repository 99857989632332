import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {usersSlice} from "../app/modules/App/_redux/users/usersSlice";
import {productsSlice} from "../app/modules/App/_redux/products/productsSlice";
import {listingsSlice} from "../app/modules/App/_redux/listings/listingsSlice";
import {ordersSlice} from "../app/modules/App/_redux/orders/ordersSlice";
import {orderItemsSlice} from "../app/modules/App/_redux/orderitems/orderItemsSlice";
import {warehousesSlice} from "../app/modules/App/_redux/warehouses/warehousesSlice";
import {pricerulesSlice} from "../app/modules/App/_redux/pricerules/pricerulesSlice";
import {listingrulesSlice} from "../app/modules/App/_redux/listingrules/listingrulesSlice";
import {listingRepriceRulesSlice} from "../app/modules/App/_redux/listingrepricerules/listingRepriceRulesSlice";
import {reportsSlice} from "../app/modules/App/_redux/reports/reportsSlice";
import {productVariantsSlice} from "../app/modules/App/_redux/productvariants/productVariantsSlice";
import {productInventoriesSlice} from "../app/modules/App/_redux/productinventories/productInventoriesSlice";
import {channelsSlice} from "../app/modules/App/_redux/channels/channelsSlice";
import {dashboardSlice} from "../app/modules/App/_redux/dashboard/dashboardSlice";
import {uploadsSlice} from "../app/modules/App/_redux/uploads/uploadsSlice";
import {virtualproductsSlice} from "../app/modules/App/_redux/virtualproducts/virtualproductsSlice";
import {repriceactivitiesSlice} from "../app/modules/App/_redux/repriceactivities/repriceactivitiesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  users: usersSlice.reducer,
  products: productsSlice.reducer,
  listings: listingsSlice.reducer,
  orders: ordersSlice.reducer,
  orderItems: orderItemsSlice.reducer,
  warehouses: warehousesSlice.reducer,
  productVariants: productVariantsSlice.reducer,
  pricerules: pricerulesSlice.reducer,
  listingrules: listingrulesSlice.reducer,
  listingrepricerules: listingRepriceRulesSlice.reducer,
  reports: reportsSlice.reducer,
  channels: channelsSlice.reducer,
  productInventories: productInventoriesSlice.reducer,
  dashboard: dashboardSlice.reducer,
  uploads: uploadsSlice.reducer,
  virtualproducts: virtualproductsSlice.reducer,
  repriceactivities: repriceactivitiesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
