import {createSlice} from "@reduxjs/toolkit";

const initialListingsState = {
  listLoading: false,
  actionsLoading: false,
  total_count: 0,
  entities: null,
  listingForEdit: undefined,
  offersForEdit: undefined,
  repriceRulesForEdit: undefined,
  repriceActivityForEdit: undefined,
  userActivityForEdit: undefined,
  listingCompetitor: undefined,
  competitorSearchResult: undefined,
  stats: undefined,
  lastError: null,
  currentChannel: {}
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const listingsSlice = createSlice({
  name: "listings",
  initialState: initialListingsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getListingById
    listingFetched: (state, action) => {
      state.actionsLoading = false;
      state.listingForEdit = action.payload.listingForEdit;
      state.error = null;
    },
     channelFetched: (state, action) => {
      state.actionsLoading = false;
      state.currentChannel = action.payload.channel;
      state.error = null;
    },
    // getCompetitorSearchResult
    competitorSearchResultFetched: (state, action) => {
      const { total_count, entities } = action.payload;
      state.actionsLoading = false;
      state.competitorSearchResult = entities;
      state.error = null;
    },
    // getCompetitorsById
    channelListingCompetitorsFetched: (state, action) => {
      state.actionsLoading = false;
      state.listingCompetitor = action?.payload ? action.payload : undefined;
      state.error = null;
    },
    // getCompetitorsById
    channelListingCompetitorsUpdated: (state, action) => {
      state.actionsLoading = false;
      state.listingCompetitor = action?.payload ? action.payload: undefined
      state.error = null;
    },
    // getListingById
    channelProductPriceFetched: (state, action) => {
      state.actionsLoading = false;
      state.offersForEdit = action.payload?.price_data?.Offers ? action.payload.price_data.Offers : undefined;
      state.error = null;
    },
    // getListingById
    listingRepriceActivityFetched: (state, action) => {
      state.actionsLoading = false;
      state.repriceActivityForEdit = action.payload;
      state.error = null;
    },
    // getListingById
    listingRepriceRuleFetched: (state, action) => {
      state.actionsLoading = false;
      state.repriceRulesForEdit = action.payload;
      state.error = null;
    },
    // getListingById
    listingUserActivityFetched: (state, action) => {
      state.actionsLoading = false;
      state.userActivityForEdit = action.payload;
      state.error = null;
    },
    listingStatsFetched: (state, action) => {
      state.actionsLoading = false;
      state.stats = action.payload;
      state.error = null;
    },
    // findListings
    listingsFetched: (state, action) => {
      const { total_count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.total_count = total_count;
    },
    // createListing
    listingCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.listing);
    },
    // add channel listing competitor
    channelListingCompetitorAdded: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if(state.competitors) {
        state.competitors.push(action.payload.channelListingCompetitor);
      } else {
        state.competitors = [action.payload.channelListingCompetitor]
      }
    },
    // updateListing
    listingUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities && state.entities.length > 0 && state.entities.map(entity => {
        if (entity.id === action.payload.listing.id) {
          entity = {...entity, ...action.payload.listing}
        }
        return entity;
      });
      state.listingForEdit = action.payload.listing
    },
    // updateListing
    listingRepriceStatusUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities && state.entities.length > 0 && state.entities.map(entity => {
        if (action.payload.ids.includes(entity.id)) {
          entity = {...entity, reprice_status:action.payload.reprice_status}
        }
        return entity;
      });
      state.listingForEdit = action.payload.listing
    },
    // deleteListing
    listingDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteListings
    listingsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // listingsRuleUpdatedState
    listingsListingRuleUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, listing_rule } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.listing_rule = listing_rule;
        }
        return entity;
      });
    },
    // listingsRuleUpdatedState
    listingsPricingRuleUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
  }
});
