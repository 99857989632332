import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchAccountUserActivity} from "../modules/App/_redux/dashboard/dashboardActions";
import dayjs from "dayjs";

export function ActivityWidget({className, title, data}) {
    const dispatch = useDispatch();
    const {userActivity} = useSelector(
        (state) => ({
            actionsLoading: state.dashboard.actionsLoading,
            userActivity: state.dashboard.userActivity,
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(fetchAccountUserActivity())
    }, []);

    return (
        <>
            <div className={`card card-custom  ${className}`}>
                {/* Header */}
                <div className="card-header align-items-center border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="font-weight-bolder text-dark">Latest User Activity</span>
                    </h3>
                </div>
                {/* Body */}
                <div className="card-body pt-4">
                    <div className="timeline timeline-6 mt-3">
                        {userActivity && userActivity.map((row, index) => (
                            <div className="timeline-item align-items-start" key={'useractivity' + index}>
                                <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                                    {dayjs(row.created_at).format('lll')}
                                </div>

                                <div className="timeline-badge">
                                    <i className="fa fa-genderless text-warning icon-xl"></i>
                                </div>

                                <div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
                                    {row.messages.map((rowMessage, ind) => (
                                        <div key={'timelinerow' + ind}>{rowMessage}</div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
